import React from "react";
import Modal from "react-bootstrap/Modal";

// styles
import * as styles from "./Modal.module.css";

const MetabolismAssessmentModal = props => {
  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.onHide}
        backdrop="static"
        keyboard={false}
        centered={true}
        size="lg"
      >
        <Modal.Body>
          <div
            onClick={props.onHide}
            className={styles.close_btn}
            role="presentation"
          >
            <i className="icon-cross" aria-hidden="true"></i>
          </div>
          <p className={styles.modal__hdng}>
            *The risk measurement inference is derived from following sources -
            Hide DetailsInference for eye complications, kidney complications
            risk, Heart attack (Myocardial infarction) risk, Amputation risk:
          </p>
          <div className={styles.modal__anchorDiv}>
            <a
              href="https://www.bmj.com/content/366/bmj.l4894 "
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>https://www.bmj.com/content/366/bmj.l4894 </p>
            </a>
            <a
              href="https://drc.bmj.com/content/8/1/e000810"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>https://drc.bmj.com/content/8/1/e000810</p>
            </a>
            <a
              href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9843200/#:~:text=The%20findings%20identified%20T2DM%20with,even%20higher%20risk%20of%20mortality"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>
                https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9843200/#:~:text=The%20findings%20identified%20T2DM%20with,even%20higher%20risk%20of%20mortality.
              </p>
            </a>
            <a
              href="https://www.jvascsurg.org/article/S0741-5214(17)31827-X/pdf#:~:text=In%20the%20Cox%20model%2C%20incremental,higher%20risk%20of%20amputation%2C%20respectively"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>
                {" "}
                https://www.jvascsurg.org/article/S0741-5214(17)31827-X/pdf#:~:text=In%20the%20Cox%20model%2C%20incremental,higher%20risk%20of%20amputation%2C%20respectively
              </p>
            </a>
            <p className={styles.modal__hdng}>
              Inference for ‘Other health risks due to smoking & drinking’: 
            </p>
            <a
              href="https://pubmed.ncbi.nlm.nih.gov/34015143/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>https://pubmed.ncbi.nlm.nih.gov/34015143/</p>
            </a>
            <a
              href="https://www.hopkinsmedicine.org/health/conditions-and-diseases/smoking-and-cardiovascular-disease#:~:text=Cigarette%20smokers%20are%202%20to,get%20heart%20disease%20than%20nonsmokers"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>
                https://www.hopkinsmedicine.org/health/conditions-and-diseases/smoking-and-cardiovascular-disease#:~:text=Cigarette%20smokers%20are%202%20to,get%20heart%20disease%20than%20nonsmokers
              </p>
            </a>
            <a
              href="https://www.cdc.gov/tobacco/campaign/tips/diseases/heart-disease-stroke.html#:~:text=Nonsmokers%20who%20breathe%20secondhand%20smoke,than%208%2C000%20deaths%20from%20stroke"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>
                https://www.cdc.gov/tobacco/campaign/tips/diseases/heart-disease-stroke.html#:~:text=Nonsmokers%20who%20breathe%20secondhand%20smoke,than%208%2C000%20deaths%20from%20stroke
              </p>
            </a>
            <a
              href="https://www.lung.org/lung-health-diseases/lung-disease-lookup/copd/what-causes-copd#:~:text=About%2075%20percent%20of%20all,chemicals%20and%20many%20are%20harmful"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>
                https://www.lung.org/lung-health-diseases/lung-disease-lookup/copd/what-causes-copd#:~:text=About%2075%20percent%20of%20all,chemicals%20and%20many%20are%20harmful.
              </p>
            </a>
            <a
              href="https://www.cdc.gov/tobacco/campaign/tips/diseases/diabetes.html#:~:text=We%20now%20know%20that%20smoking%20is%20one%20cause%20of%20type%202%20diabetes.&text=In%20fact%2C%20people%20who%20smoke,people%20who%20don't%20smoke"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>
                https://www.cdc.gov/tobacco/campaign/tips/diseases/diabetes.html#:~:text=We%20now%20know%20that%20smoking%20is%20one%20cause%20of%20type%202%20diabetes.&text=In%20fact%2C%20people%20who%20smoke,people%20who%20don't%20smoke.
              </p>
            </a>
            <a
              href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9252244/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9252244/</p>
            </a>
            <a
              href="https://pennstatehealthnews.org/topics/one-group-blog-october-2021/#:~:text=Strong%20evidence%20shows%20that%20alcohol,20%25%20to%2050%25%20increased%20risk"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>
                https://pennstatehealthnews.org/topics/one-group-blog-october-2021/#:~:text=Strong%20evidence%20shows%20that%20alcohol,20%25%20to%2050%25%20increased%20risk
              </p>
            </a>
            <a
              href="https://jamanetwork.com/journals/jama/fullarticle/190755"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>https://jamanetwork.com/journals/jama/fullarticle/190755</p>
            </a>
            <a
              href="https://jamanetwork.com/journals/jama/fullarticle/190755"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>https://jamanetwork.com/journals/jama/fullarticle/190755</p>
            </a>
            <a
              href="https://www.bhf.org.uk/informationsupport/heart-matters-magazine/news/behind-the-headlines/alcohol-and-stroke-risk"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>
                https://www.bhf.org.uk/informationsupport/heart-matters-magazine/news/behind-the-headlines/alcohol-and-stroke-risk
              </p>
            </a>
            <a
              href="https://www.ncbi.nlm.nih.gov/books/NBK546632/#:~:text=Daily%20consumption%20of%2030%20to,more%20than%2040%20g%2Fday"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>
                https://www.ncbi.nlm.nih.gov/books/NBK546632/#:~:text=Daily%20consumption%20of%2030%20to,more%20than%2040%20g%2Fday.
              </p>
            </a>
            <a
              href="https://www.ncbi.nlm.nih.gov/books/NBK546632/#:~:text=Daily%20consumption%20of%2030%20to,more%20than%2040%20g%2Fday"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>
                https://www.ncbi.nlm.nih.gov/books/NBK546632/#:~:text=Daily%20consumption%20of%2030%20to,more%20than%2040%20g%2Fday.
              </p>
            </a>
            <a
              href="https://www.ahajournals.org/doi/10.1161/01.HYP.37.5.1242"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>https://www.ahajournals.org/doi/10.1161/01.HYP.37.5.1242</p>
            </a>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MetabolismAssessmentModal;
