import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { navigate } from "gatsby";
import Loader from "react-loader-spinner";

// constants
import constants from "../../../constants";

// clevertap
import { sendToCT } from "../../../services/Clevertap";

// component
import CircleProgressBar from "../../CircleProgressBar/CircleProgressBar";
import Modal from "../Modal/Modal";
import FloatingCta from "../FloatingCta/FloatingCta";

//css
import * as styles from "./ResultPage.module.css";

const ResultPage = props => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleBookConsultationCta = () => {
    setLoading(true);

    // Simulating a delay before navigating to the Calendly URL
    setTimeout(() => {
      navigate(props.calendlyUrl);

      // Set a timeout to hide the loader after a reasonable time (e.g., 5 seconds)
      setTimeout(() => {
        setLoading(false);
      }, 5000);
    }, 1000);

    // clevertap event
    sendToCT(props.ctEvent, {
      action: "on clicking Book FREE consultation cta result page",
    });
  };

  // Modal Functions
  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <div>
      {/* Floating Cta For Mobile */}
      {
        <FloatingCta
          ctaText="Book FREE Consultation"
          calendlyUrl={props.calendlyUrl}
          handleNextStep={props.handleNextStep}
          ctEvent={props.ctEvent}
        />
      }

      {props.finalQuizScore &&
      props.userQuizResult.sugarLevel &&
      props.bmiScore &&
      props.riskState ? (
        <div className={`container ${styles.resultPge__cont}`}>
          {/* Section 1 */}
          <div className="row">
            <div className={`col-sm-12 col-xs-12 col-md-4 col-lg-4`}>
              <div
                className={
                  props.finalQuizScore > 90
                    ? styles.resultPge__barSecLow
                    : props.finalQuizScore > 70 && props.finalQuizScore <= 90
                    ? styles.resultPge__barSecModerate
                    : styles.resultPge__barSecHigh
                }
              >
                <h3>Your health score is</h3>
                <div className={styles.resultPge__circleBar}>
                  <CircleProgressBar
                    percentage={props.finalQuizScore}
                    strokeWidth={10}
                    size={150}
                    circle1BkgColor={
                      props.finalQuizScore > 90
                        ? "#3E7B00"
                        : props.finalQuizScore > 70 &&
                          props.finalQuizScore <= 90
                        ? "#D26E00"
                        : "#c8072a"
                    }
                    circle2BkgColor={
                      props.finalQuizScore > 90
                        ? "#3E7B00"
                        : props.finalQuizScore > 70 &&
                          props.finalQuizScore <= 90
                        ? "#D26E00"
                        : "#c8072a"
                    }
                    stroke1Color="#FFF"
                    stroke2Color="#e0e0e0"
                    textColor="#ffffff"
                  />
                </div>
                <p>{props.riskState}</p>
              </div>
            </div>
            <div className={`col-sm-12 col-xs-12 col-md-8 col-lg-8`}>
              {props.finalQuizScore > 90 ? (
                <div className={`${styles.resultPge__barSec__rightCntn}`}>
                  <h3>
                    {constants.METABOLIC_ASSESSMENT_RESULTPAGE_PARA.heading1}
                  </h3>
                </div>
              ) : (
                <div>
                  {props.finalQuizScore > 70 && props.finalQuizScore <= 90 ? (
                    <div className={`${styles.resultPge__barSec__rightCntn}`}>
                      <h3>
                        {
                          constants.METABOLIC_ASSESSMENT_RESULTPAGE_PARA
                            .heading2
                        }
                      </h3>
                    </div>
                  ) : (
                    <div className={`${styles.resultPge__barSec__rightCntn}`}>
                      <h3>
                        {
                          constants.METABOLIC_ASSESSMENT_RESULTPAGE_PARA
                            .heading3
                        }
                      </h3>
                    </div>
                  )}
                </div>
              )}
              {props.finalQuizScore < 70 ? (
                <div className={styles.resultPge__barSec__rightCntn}>
                  <p>
                    {constants.METABOLIC_ASSESSMENT_RESULTPAGE_PARA.subHeading1}
                  </p>
                </div>
              ) : (
                <div>
                  {props.finalQuizScore > 70 && props.finalQuizScore <= 90 ? (
                    <div className={styles.resultPge__barSec__rightCntn}>
                      <p>
                        {
                          constants.METABOLIC_ASSESSMENT_RESULTPAGE_PARA
                            .subHeading2
                        }
                      </p>
                    </div>
                  ) : (
                    <div className={styles.resultPge__barSec__rightCntn}>
                      <p>
                        {
                          constants.METABOLIC_ASSESSMENT_RESULTPAGE_PARA
                            .subHeading3
                        }
                      </p>
                    </div>
                  )}
                </div>
              )}

              {props.finalQuizScore <= 90 ? (
                <div className={styles.resultPge__barSec__rightCntn}>
                  <p>{constants.METABOLIC_ASSESSMENT_RESULTPAGE_PARA.para1}</p>
                </div>
              ) : null}

              <div className={styles.resultPge__barSec__rightCntn}>
                <p>{constants.METABOLIC_ASSESSMENT_RESULTPAGE_PARA.para2}</p>
              </div>

              {/* Desktop View Only */}
              <div className={`${styles.resultPge__bmiDiv}`}>
                <div className={styles.resultPge__bmiHba1cDiv}>
                  <div className={styles.resultPge__bmiDivPara}>
                    <h3>
                      BMI{" "}
                      <span
                        className={`icon-arrow-forward`}
                        aria-hidden="true"
                      ></span>{" "}
                      <span
                        className={
                          props.bmiScore >= 30
                            ? styles.resultPge__bmiHba1c__red
                            : props.bmiScore >= 25 && props.bmiScore < 30
                            ? styles.resultPge__bmiHba1c__orange
                            : props.bmiScore >= 18 && props.bmiScore < 25
                            ? styles.resultPge__bmiHba1c__green
                            : styles.resultPge__bmiHba1c__orange
                        }
                      >
                        {props.bmiScore}
                      </span>
                    </h3>{" "}
                  </div>
                  <div className={styles.resultPge__hba1cDivPara}>
                    <h3>
                      HbA1c{" "}
                      <span
                        className={`icon-arrow-forward`}
                        aria-hidden="true"
                      ></span>{" "}
                      <span
                        className={
                          props.userQuizResult.sugarLevel === 22 ||
                          props.userQuizResult.sugarLevel === 18
                            ? styles.resultPge__bmiHba1c__green
                            : props.userQuizResult.sugarLevel === 13
                            ? styles.resultPge__bmiHba1c__orange
                            : props.userQuizResult.sugarLevel === 9 ||
                              props.userQuizResult.sugarLevel === 4
                            ? styles.resultPge__bmiHba1c__red
                            : ""
                        }
                      >
                        {props.userQuizResult.sugarLevelOption}
                      </span>
                    </h3>{" "}
                  </div>
                </div>
                <div className={styles.resultPge__topCta}>
                  <button onClick={handleBookConsultationCta}>
                    <span className={loading && styles.resultPge__ctaLoader}>
                      Book FREE consultation{" "}
                    </span>
                    <span
                      className={`icon-arrow-forward`}
                      aria-hidden="true"
                    ></span>
                    <span className={styles.resultPge__loader}>
                      <Loader
                        type="TailSpin"
                        color="#FFF"
                        height={30}
                        width={30}
                        radius={1}
                        visible={loading}
                      />
                    </span>
                  </button>
                </div>
              </div>
              {/* Desktop View Only */}
              <div className={`${styles.resultPge__bmiHbacDiv}`}>
                {/* BMI PARA */}
                <div className={styles.resultPge__bmiPara}>
                  {props.bmiScore >= 30 && (
                    <div>
                      <p className={styles.resultPge__bmiHba1c__red}>
                        You’re Obese
                      </p>
                    </div>
                  )}
                  {25 <= props.bmiScore && props.bmiScore < 30 && (
                    <div>
                      <p className={styles.resultPge__bmiHba1c__orange}>
                        You’re Overweight
                      </p>
                    </div>
                  )}
                  {18 < props.bmiScore && props.bmiScore < 25 && (
                    <div>
                      <p className={styles.resultPge__bmiHba1c__green}>
                        Normal Weight
                      </p>
                    </div>
                  )}
                  {props.bmiScore < 18 && (
                    <div>
                      <p className={styles.resultPge__bmiHba1c__orange}>
                        Underweight
                      </p>
                    </div>
                  )}
                </div>
                {/* HBA1C PARA */}
                <div className={styles.resultPge__hba1cPara}>
                  {props.userQuizResult.sugarLevel === 22 && (
                    <div>
                      <p className={styles.resultPge__bmiHba1c__green}>
                        Excellent Control
                      </p>
                    </div>
                  )}
                  {props.userQuizResult.sugarLevel === 18 && (
                    <div>
                      <p className={styles.resultPge__bmiHba1c__green}>
                        Good Control
                      </p>
                    </div>
                  )}
                  {props.userQuizResult.sugarLevel === 13 && (
                    <div>
                      <p className={styles.resultPge__bmiHba1c__orange}>
                        Action Suggested
                      </p>
                    </div>
                  )}
                  {props.userQuizResult.sugarLevel === 9 && (
                    <div>
                      <p className={styles.resultPge__bmiHba1c__red}>
                        Poor Control
                      </p>
                    </div>
                  )}
                  {props.userQuizResult.sugarLevel === 4 && (
                    <div>
                      <p className={styles.resultPge__bmiHba1c__red}>
                        Extremely Uncontrolled
                      </p>
                    </div>
                  )}
                </div>
              </div>

              {/* BMI -> Mobile View Only */}
              <div className={styles.resultPge__bmiDiv__mob}>
                <div className={styles.resultPge__bmiDivPara__mob}>
                  <h3>
                    BMI{" "}
                    <span
                      className={`icon-arrow-forward`}
                      aria-hidden="true"
                    ></span>{" "}
                    <span
                      className={
                        props.bmiScore >= 30
                          ? styles.resultPge__bmiHba1c__red
                          : props.bmiScore >= 25 && props.bmiScore < 30
                          ? styles.resultPge__bmiHba1c__orange
                          : props.bmiScore >= 18 && props.bmiScore < 25
                          ? styles.resultPge__bmiHba1c__green
                          : styles.resultPge__bmiHba1c__orange
                      }
                    >
                      {props.bmiScore}
                    </span>
                  </h3>{" "}
                </div>
                <div>
                  {props.bmiScore >= 30 && (
                    <div>
                      <p className={styles.resultPge__bmiHba1c__red}>
                        You’re Obese
                      </p>
                    </div>
                  )}
                  {25 <= props.bmiScore && props.bmiScore < 30 && (
                    <div>
                      <p className={styles.resultPge__bmiHba1c__orange}>
                        You’re Overweight
                      </p>
                    </div>
                  )}
                  {18 < props.bmiScore && props.bmiScore < 25 && (
                    <div>
                      <p className={styles.resultPge__bmiHba1c__green}>
                        Normal Weight
                      </p>
                    </div>
                  )}
                  {props.bmiScore < 18 && (
                    <div>
                      <p className={styles.resultPge__bmiHba1c__orange}>
                        Underweight
                      </p>
                    </div>
                  )}
                </div>
              </div>
              {/* HBA1C -> Mobile View Only */}
              <div className={styles.resultPge__bmiDiv__mob}>
                <div classname={styles.resultPge__bmiDivPara__mob}>
                  <h3>
                    HbA1c{" "}
                    <span
                      className={`icon-arrow-forward`}
                      aria-hidden="true"
                    ></span>{" "}
                    <span
                      className={
                        props.userQuizResult.sugarLevel === 22 ||
                        props.userQuizResult.sugarLevel === 18
                          ? styles.resultPge__bmiHba1c__green
                          : props.userQuizResult.sugarLevel === 13
                          ? styles.resultPge__bmiHba1c__orange
                          : props.userQuizResult.sugarLevel === 9 ||
                            props.userQuizResult.sugarLevel === 4
                          ? styles.resultPge__bmiHba1c__red
                          : styles.resultPge__bmiHba1c__green
                      }
                    >
                      {props.userQuizResult.sugarLevelOption}
                    </span>
                  </h3>{" "}
                </div>
                <div>
                  {props.userQuizResult.sugarLevel === 22 && (
                    <div>
                      <p className={styles.resultPge__bmiHba1c__green}>
                        Excellent Control
                      </p>
                    </div>
                  )}
                  {props.userQuizResult.sugarLevel === 18 && (
                    <div>
                      <p className={styles.resultPge__bmiHba1c__green}>
                        Good Control
                      </p>
                    </div>
                  )}
                  {props.userQuizResult.sugarLevel === 13 && (
                    <div>
                      <p className={styles.resultPge__bmiHba1c__orange}>
                        Action Suggested
                      </p>
                    </div>
                  )}
                  {props.userQuizResult.sugarLevel === 9 && (
                    <div>
                      <p className={styles.resultPge__bmiHba1c__red}>
                        Poor Control
                      </p>
                    </div>
                  )}
                  {props.userQuizResult.sugarLevel === 4 && (
                    <div>
                      <p className={styles.resultPge__bmiHba1c__red}>
                        Extremely Uncontrolled
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* Section 2 */}
          <div className={styles.resultPge__reportRow}>
            <div className="row">
              <div className={`col-12 ${styles.resultPge__reportHdng}`}>
                <h3>Your Current Health Risk</h3>
                <p>
                  Potential health risks you might face or are already facing if
                  immediate lifestyle changes are not made
                </p>
              </div>
            </div>
            <div className="row">
              <div className={`col-12`}>
                <div
                  className={`text-center ${styles.resultPge__reportMiddleImg__mob}`}
                >
                  <StaticImage
                    src="../../../images/quiz/body.png"
                    alt="Banner"
                    placeholder="blurred"
                    quality={90}
                  />
                </div>
              </div>
            </div>
            <div className={`row ${styles.resultPge__reportSecRow}`}>
              <div className={`col-xs-12 col-sm-12 col-md-4 col-lg-4`}>
                {/* Amputation Risk */}
                <div>
                  <div
                    className={`d-flex align-items-center justify-content-between`}
                  >
                    <div
                      className={`d-flex align-items-center ${styles.resultPge__reportLeft}`}
                    >
                      <StaticImage
                        src="../../../images/quiz/accessible.png"
                        alt="Banner"
                        placeholder="blurred"
                        quality={90}
                      />
                      <h3>Amputation risk</h3>
                    </div>
                    <p
                      className={
                        props.potentialRisk.amputation === "high risk"
                          ? styles.resultPge__highRisk
                          : props.potentialRisk.amputation === "moderate risk"
                          ? styles.resultPge__moderateRisk
                          : props.potentialRisk.amputation === "low risk"
                          ? styles.resultPge__lowRisk
                          : styles.resultPge__default
                      }
                    >
                      {props.potentialRisk.amputation}
                    </p>
                  </div>
                  <div>
                    <p>
                      Amputation is the loss or removal of a body part such as a
                      finger, toe, hand, foot, arm or leg.
                    </p>
                  </div>
                </div>
                {/* Hypertension Risk */}
                {/* <div className={styles.resultPge__reportLine}></div> */}
                {/* <div>
                  <div
                    className={`d-flex align-items-center justify-content-between`}
                  >
                    <div
                      className={`d-flex align-items-center ${styles.resultPge__reportLeft}`}
                    >
                      <StaticImage
                        src="../../../images/quiz/cognition.png"
                        alt="Banner"
                        placeholder="blurred"
                        quality={90}
                      />
                      <h3>Hypertension</h3>
                    </div>
                    <p
                      className={
                        props.potentialRisk.hypertension === "high risk"
                          ? styles.resultPge__highRisk
                          : props.potentialRisk.hypertension === "moderate risk"
                          ? styles.resultPge__moderateRisk
                          : props.potentialRisk.hypertension === "low risk"
                          ? styles.resultPge__lowRisk
                          : styles.resultPge__default
                      }
                    >
                      {props.potentialRisk.hypertension}
                    </p>
                  </div>
                  <div>
                    <p>
                      Hypertension (high blood pressure) is when the pressure in
                      your blood vessels is too high (140/90 mmHg or higher).
                    </p>
                  </div>
                </div> */}
                <div className={styles.resultPge__reportLine}></div>
                {/* Eye complications */}
                <div>
                  <div
                    className={`d-flex align-items-center justify-content-between`}
                  >
                    <div
                      className={`d-flex align-items-center ${styles.resultPge__reportLeft}`}
                    >
                      <StaticImage
                        src="../../../images/quiz/eye.png"
                        alt="Banner"
                        placeholder="blurred"
                        quality={90}
                      />
                      <h3>Eye complications </h3>
                    </div>
                    <p
                      className={
                        props.potentialRisk.eye === "high risk"
                          ? styles.resultPge__highRisk
                          : props.potentialRisk.eye === "moderate risk"
                          ? styles.resultPge__moderateRisk
                          : props.potentialRisk.eye === "low risk"
                          ? styles.resultPge__lowRisk
                          : styles.resultPge__default
                      }
                    >
                      {props.potentialRisk.eye}
                    </p>
                  </div>
                  <div>
                    <p>
                      People with diabetes may experience eye complications that
                      can result in impaired vision or blindness.
                    </p>
                  </div>
                </div>
                <div className={styles.resultPge__reportLine}></div>
                {/* Heart attack */}
                <div>
                  <div
                    className={`d-flex align-items-center justify-content-between`}
                  >
                    <div
                      className={`d-flex align-items-center ${styles.resultPge__reportLeft}`}
                    >
                      <StaticImage
                        src="../../../images/quiz/ecg.png"
                        alt="Banner"
                        placeholder="blurred"
                        quality={90}
                      />
                      <h3>
                        Heart attack <br></br>(Myocardial infarction)
                      </h3>
                    </div>
                    <p
                      className={
                        props.potentialRisk.heartAttack === "high risk"
                          ? styles.resultPge__highRisk
                          : props.potentialRisk.heartAttack === "moderate risk"
                          ? styles.resultPge__moderateRisk
                          : props.potentialRisk.heartAttack === "low risk"
                          ? styles.resultPge__lowRisk
                          : styles.resultPge__default
                      }
                    >
                      {props.potentialRisk.heartAttack}
                    </p>
                  </div>
                  <div>
                    <p>
                      A heart attack occurs when blood flow to a part of the
                      heart is blocked, often by a clot, leading to damage or
                      death of the heart muscle.
                    </p>
                  </div>
                </div>
                <div className={styles.resultPge__reportLine}></div>
                {/* kidney */}
                <div>
                  <div
                    className={`d-flex align-items-center justify-content-between`}
                  >
                    <div
                      className={`d-flex align-items-center ${styles.resultPge__reportLeft}`}
                    >
                      <StaticImage
                        src="../../../images/quiz/nephrology.png"
                        alt="Banner"
                        placeholder="blurred"
                        quality={90}
                      />
                      <h3>Kidney complications </h3>
                    </div>
                    <p
                      className={
                        props.potentialRisk.kidney === "high risk"
                          ? styles.resultPge__highRisk
                          : props.potentialRisk.kidney === "moderate risk"
                          ? styles.resultPge__moderateRisk
                          : props.potentialRisk.kidney === "low risk"
                          ? styles.resultPge__lowRisk
                          : styles.resultPge__default
                      }
                    >
                      {props.potentialRisk.kidney}
                    </p>
                  </div>
                  <div>
                    <p>
                      Diabetic nephropathy is a serious complication of type 2
                      diabetes. It's also called diabetic kidney disease.
                    </p>
                  </div>
                </div>
              </div>
              <div className={`col-xs-12 col-sm-12 col-md-4 col-lg-4`}>
                <div
                  className={`text-center ${styles.resultPge__reportMiddleImg}`}
                >
                  <StaticImage
                    src="../../../images/quiz/body.png"
                    alt="Banner"
                    placeholder="blurred"
                    quality={90}
                  />
                </div>
              </div>
              <div className={`col-xs-12 col-sm-12 col-md-4 col-lg-4`}>
                <div className={styles.resultPge__reportRightSec}>
                  <h3>Other health risks due to smoking & drinking</h3>
                  {/* Fatty Liver */}
                  <div>
                    <div
                      className={`d-flex align-items-center ${styles.resultPge__reportRightSec__topCntn}`}
                    >
                      <StaticImage
                        src="../../../images/quiz/fattyliver.png"
                        alt="Banner"
                        placeholder="blurred"
                        quality={90}
                      />
                      <h3>Fatty liver</h3>
                      <p
                        className={
                          props.potentialRisk.fattyLiver === "high risk"
                            ? styles.resultPge__highRisk
                            : props.potentialRisk.fattyLiver === "moderate risk"
                            ? styles.resultPge__moderateRisk
                            : props.potentialRisk.fattyLiver === "low risk"
                            ? styles.resultPge__lowRisk
                            : styles.resultPge__default
                        }
                      >
                        {props.potentialRisk.fattyLiver}
                      </p>
                    </div>
                    <div className={styles.resultPge__reportRightSec__bottom}>
                      <p>
                        Fatty liver is a condition caused by the storage of
                        extra fat in the liver.
                      </p>
                    </div>
                  </div>
                  <div className={styles.resultPge__reportLineRight}></div>
                  {/* Lung Diseases */}
                  <div>
                    <div
                      className={`d-flex align-items-center ${styles.resultPge__reportRightSec__topCntn}`}
                    >
                      <StaticImage
                        src="../../../images/quiz/kidney.png"
                        alt="Banner"
                        placeholder="blurred"
                        quality={90}
                      />
                      <h3>Lung Diseases</h3>
                      <p
                        className={
                          props.potentialRisk.lung === "high risk"
                            ? styles.resultPge__highRisk
                            : props.potentialRisk.lung === "moderate risk"
                            ? styles.resultPge__moderateRisk
                            : props.potentialRisk.lung === "low risk"
                            ? styles.resultPge__lowRisk
                            : styles.resultPge__default
                        }
                      >
                        {props.potentialRisk.lung}
                      </p>
                    </div>
                    <div className={styles.resultPge__reportRightSec__bottom}>
                      <p>
                        Lung disease can affect respiratory function, or the
                        ability to breathe.
                      </p>
                    </div>
                  </div>
                  <div className={styles.resultPge__reportLineRight}></div>
                  {/* Cancer */}
                  <div>
                    <div
                      className={`d-flex align-items-center ${styles.resultPge__reportRightSec__topCntn}`}
                    >
                      <StaticImage
                        src="../../../images/quiz/cancer.png"
                        alt="Banner"
                        placeholder="blurred"
                        quality={90}
                      />
                      <h3>Cancer</h3>
                      <p
                        className={
                          props.potentialRisk.cancer === "high risk"
                            ? styles.resultPge__highRisk
                            : props.potentialRisk.cancer === "moderate risk"
                            ? styles.resultPge__moderateRisk
                            : props.potentialRisk.cancer === "low risk"
                            ? styles.resultPge__lowRisk
                            : styles.resultPge__default
                        }
                      >
                        {props.potentialRisk.cancer}
                      </p>
                    </div>
                    <div className={styles.resultPge__reportRightSec__bottom}>
                      <p>
                        Cancer is uncontrolled cell growth forming tumors,
                        causing harm by invading tissues and spreading.
                      </p>
                    </div>
                  </div>
                  <div className={styles.resultPge__reportLineRight}></div>
                  {/* Liver Cirrhosis */}
                  <div>
                    <div
                      className={`d-flex align-items-center ${styles.resultPge__reportRightSec__topCntn}`}
                    >
                      <StaticImage
                        src="../../../images/quiz/liver.png"
                        alt="Banner"
                        placeholder="blurred"
                        quality={90}
                      />
                      <h3>Liver Cirrhosis</h3>
                      <p
                        className={
                          props.potentialRisk.liver === "high risk"
                            ? styles.resultPge__highRisk
                            : props.potentialRisk.liver === "moderate risk"
                            ? styles.resultPge__moderateRisk
                            : props.potentialRisk.liver === "low risk"
                            ? styles.resultPge__lowRisk
                            : styles.resultPge__default
                        }
                      >
                        {props.potentialRisk.liver}
                      </p>
                    </div>
                    <div className={styles.resultPge__reportRightSec__bottom}>
                      <p>
                        Liver cirrhosis is severe scarring of the liver,
                        impairing its function and posing life-threatening
                        complications.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`row ${styles.resultPge__rowlLine}`}></div>
          {/* 3rd Section */}
          <div className="row">
            <div className="col-sm-12 col-xs-12 col-md-8 col-lg-8">
              <div className={styles.resultPge__revDiabCol}>
                <h3>Reverse Diabetes and Stop Health Risks</h3>
                <p>
                  Yes it's possible to live a diabetes-free life with Twin
                  Health's Diabetes reversal program & prevent all
                  diabetes-related health complications in just 4 phases
                </p>
                <div className={styles.resultPge__revDiabImg}>
                  <StaticImage
                    src="../../../images/quiz/graph.png"
                    alt="Banner"
                    placeholder="blurred"
                    quality={90}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-xs-12 col-md-4 col-lg-4">
              <div className={styles.resultPge__revDiab__rightCol}>
                <h3>4 Phases of Treatment</h3>
                <div className={`${styles.resultPge__revDiab__rightCntn}`}>
                  <h4>01</h4>
                  <StaticImage
                    src="../../../images/quiz/result_icon1.png"
                    alt="Banner"
                    placeholder="blurred"
                    quality={90}
                  />
                  <p>Reducing sugar levels, getting HbA1C below 6.5</p>
                </div>
                <div className={styles.resultPge__line}></div>
                <div className={styles.resultPge__revDiab__rightCntn}>
                  <h4>02</h4>
                  <StaticImage
                    src="../../../images/quiz/result_icon4.png"
                    alt="Banner"
                    placeholder="blurred"
                    quality={90}
                  />
                  <p>
                    Treating chronic conditions like BP, Cholesterol & Obesity
                  </p>
                </div>
                <div className={styles.resultPge__line}></div>
                <div className={styles.resultPge__revDiab__rightCntn}>
                  <h4>03</h4>
                  <StaticImage
                    src="../../../images/quiz/result_icon3.png"
                    alt="Banner"
                    placeholder="blurred"
                    quality={90}
                  />
                  <p>Improving Organ Health - Liver, Kidney & Pancreas</p>
                </div>
                <div className={styles.resultPge__line}></div>
                <div className={styles.resultPge__revDiab__rightCntn}>
                  <h4>04</h4>
                  <StaticImage
                    src="../../../images/quiz/result_icon2.png"
                    alt="Banner"
                    placeholder="blurred"
                    quality={90}
                  />
                  <p>Beta cells regeneration to complete reversal</p>
                </div>
              </div>
            </div>
          </div>

          {/* 4th Section */}
          <div className={`row ${styles.resultPge__rowlLine}`}></div>
          <div className="row">
            <div className="col-12">
              <div className={styles.resultPge__unlockPathDiv}>
                <div className={styles.resultPge__unlockPathDiv__para}>
                  <h3>Unlock the path to a healthier, diabetes-free life</h3>
                  <p>
                    Reserve your FREE consultation today and learn how Twin
                    Health can help you recover from diabetes!
                  </p>
                </div>
                <div className={styles.resultPge__topCta}>
                  <button onClick={handleBookConsultationCta}>
                    <span className={loading && styles.resultPge__ctaLoader}>
                      Book FREE consultation{" "}
                    </span>
                    <span
                      className={`icon-arrow-forward`}
                      aria-hidden="true"
                    ></span>
                    <span className={styles.resultPge__loader}>
                      <Loader
                        type="TailSpin"
                        color="#FFF"
                        height={30}
                        width={30}
                        radius={1}
                        visible={loading}
                      />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* 5th Section */}
          <div className="row">
            <div className="col-12">
              <div className={styles.resultPge__aboutTwinDiv}>
                <h3>About Twin Health</h3>
              </div>
            </div>
            <div className="col-12">
              <div className={styles.resultPge__aboutTwinDiv__para}>
                <p>
                  Founded by Dr Jahangir Mohammed, Twin Health, Inc. pioneered
                  Whole Body Digital Twin™, a precision health platform, to help
                  members safely reverse type 2 diabetes and other chronic
                  diseases.
                  <ul>
                    <li>
                      {" "}
                      With artificial intelligence and Internet of Things
                      technologies, the Twin Health Platform offers members a
                      highly technical yet highly personalized experience as
                      physicians and coaches guide them to repair the root cause
                      of disease.
                    </li>
                    <li>
                      Twin Health is present in India and the US and comprises a
                      team of 500+ passionate people across nations and is
                      published in over 20 peer-reviewed journals and research
                      papers.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>

          {/* Line */}
          <div className={`row ${styles.resultPge__rowlLine}`}></div>

          {/* 6th Section - Footer */}
          <div className="row">
            <div className="col-12">
              <div className={styles.resultPge__footer}>
                <h3>
                  Disclaimer: Please note that the test is intended for
                  informational purposes only and is not intended to be a
                  substitute for medical advice. It is important to consult with
                  a qualified healthcare provider to determine if you have any
                  chronic metabolic condition.{" "}
                </h3>
                <p>
                  *The risk measurement inference is derived from following
                  sources -{" "}
                  <span aria-hidden="true" onClick={handleOpenModal}>
                    View Details
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        navigate("/quiz/diabetes-lp")
      )}

      {/* Modal */}
      <div>
        <Modal show={showModal} onHide={() => handleCloseModal()} />
      </div>
    </div>
  );
};

export default ResultPage;
