// extracted by mini-css-extract-plugin
export var resultPge__aboutTwinDiv = "ResultPage-module--resultPge__aboutTwinDiv--ANPP2";
export var resultPge__aboutTwinDiv__para = "ResultPage-module--resultPge__aboutTwinDiv__para--s1IgK";
export var resultPge__barSecHigh = "ResultPage-module--resultPge__barSecHigh--Uh0aV";
export var resultPge__barSecLow = "ResultPage-module--resultPge__barSecLow--BE7yB";
export var resultPge__barSecModerate = "ResultPage-module--resultPge__barSecModerate--heokq";
export var resultPge__barSec__rightCntn = "ResultPage-module--resultPge__barSec__rightCntn--IRQIn";
export var resultPge__bmiDiv = "ResultPage-module--resultPge__bmiDiv--0ONYT";
export var resultPge__bmiDivPara = "ResultPage-module--resultPge__bmiDivPara--yay89";
export var resultPge__bmiDivPara__mob = "ResultPage-module--resultPge__bmiDivPara__mob--lwt2l";
export var resultPge__bmiDiv__cntn = "ResultPage-module--resultPge__bmiDiv__cntn--bgaBC";
export var resultPge__bmiDiv__mob = "ResultPage-module--resultPge__bmiDiv__mob--1ICxc";
export var resultPge__bmiHba1cDiv = "ResultPage-module--resultPge__bmiHba1cDiv--lCFEr";
export var resultPge__bmiHba1c__green = "ResultPage-module--resultPge__bmiHba1c__green--xL++0";
export var resultPge__bmiHba1c__orange = "ResultPage-module--resultPge__bmiHba1c__orange--UFTyB";
export var resultPge__bmiHba1c__red = "ResultPage-module--resultPge__bmiHba1c__red--jcqXN";
export var resultPge__bmiHbacDiv = "ResultPage-module--resultPge__bmiHbacDiv--NY0wo";
export var resultPge__bmiPara = "ResultPage-module--resultPge__bmiPara--MN0mp";
export var resultPge__circleBar = "ResultPage-module--resultPge__circleBar--u2dlE";
export var resultPge__cont = "ResultPage-module--resultPge__cont--BudkN";
export var resultPge__ctaLoader = "ResultPage-module--resultPge__ctaLoader--B70t6";
export var resultPge__default = "ResultPage-module--resultPge__default--3r3MO";
export var resultPge__footer = "ResultPage-module--resultPge__footer--mFgSr";
export var resultPge__hba1cDivPara = "ResultPage-module--resultPge__hba1cDivPara--0kK3+";
export var resultPge__hba1cPara = "ResultPage-module--resultPge__hba1cPara--g3-u-";
export var resultPge__highRisk = "ResultPage-module--resultPge__highRisk---rNZH";
export var resultPge__line = "ResultPage-module--resultPge__line--20W1-";
export var resultPge__loader = "ResultPage-module--resultPge__loader--6GN0z";
export var resultPge__lowRisk = "ResultPage-module--resultPge__lowRisk--NpyBU";
export var resultPge__moderateRisk = "ResultPage-module--resultPge__moderateRisk--l27Wp";
export var resultPge__reportHdng = "ResultPage-module--resultPge__reportHdng--PrFjI";
export var resultPge__reportLeft = "ResultPage-module--resultPge__reportLeft--Pdbsw";
export var resultPge__reportLine = "ResultPage-module--resultPge__reportLine--n0gcA";
export var resultPge__reportLineRight = "ResultPage-module--resultPge__reportLineRight--rryvT";
export var resultPge__reportMiddleImg = "ResultPage-module--resultPge__reportMiddleImg--fDmFX";
export var resultPge__reportMiddleImg__mob = "ResultPage-module--resultPge__reportMiddleImg__mob--XUkqX";
export var resultPge__reportRightSec = "ResultPage-module--resultPge__reportRightSec--h44e2";
export var resultPge__reportRightSec__bottom = "ResultPage-module--resultPge__reportRightSec__bottom--7xA-s";
export var resultPge__reportRightSec__topCntn = "ResultPage-module--resultPge__reportRightSec__topCntn--Zrbpg";
export var resultPge__reportRow = "ResultPage-module--resultPge__reportRow--0z4-T";
export var resultPge__reportSecRow = "ResultPage-module--resultPge__reportSecRow--TWBV6";
export var resultPge__revDiabCol = "ResultPage-module--resultPge__revDiabCol--Paiob";
export var resultPge__revDiabImg = "ResultPage-module--resultPge__revDiabImg--tfsTG";
export var resultPge__revDiab__rightCntn = "ResultPage-module--resultPge__revDiab__rightCntn---hxSj";
export var resultPge__revDiab__rightCol = "ResultPage-module--resultPge__revDiab__rightCol--KjEVD";
export var resultPge__rowlLine = "ResultPage-module--resultPge__rowlLine--Djuis";
export var resultPge__topCta = "ResultPage-module--resultPge__topCta--WYgns";
export var resultPge__unlockPathDiv = "ResultPage-module--resultPge__unlockPathDiv--ln1KS";
export var resultPge__unlockPathDiv__para = "ResultPage-module--resultPge__unlockPathDiv__para--xeUSn";