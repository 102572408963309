import React, { useState, useEffect } from "react";

// seo
import Seo from "../../components/Seo";

// local storage
import LocalStorageService from "../../utils/LocalStorageService";

// components
import HeaderWithLogo from "../../components/Header/HeaderWithLogo";
import ResultPage from "../../components/MetabolismAssessment/ResultPage/ResultPage";

const DiabetesQuizResultPage = () => {
  const [loading, setLoading] = useState(true);
  const [quizData, setQuizData] = useState({});
  const [currentMonth, setCurrentMonth] = useState("");
  const [currentYear, setCurrentYear] = useState("");

  useEffect(() => {
    const currentDate = new Date();
    const month = currentDate.getMonth();
    const year = currentDate.getFullYear();

    setCurrentMonth(month + 1);
    setCurrentYear(year);

    const keys = [
      "userQuizResult",
      "finalQuizScore",
      "potentialRisk",
      "bmiScore",
      "riskState",
      "userName",
      "userEmail",
    ];

    const getQuizData = keys.reduce((acc, key) => {
      acc[key] = LocalStorageService.getValue(key);
      return acc;
    }, {});

    setQuizData(getQuizData);
    setLoading(false);
  }, []);

  return (
    <div>
      <Seo
        title="Diabetes Risk Assessment Quiz | Twin"
        description="The quiz will help you in taking action against diabetes."
      />
      <HeaderWithLogo logoPosition="center" background="white" />
      {loading ? (
        <div className="row">
          <div className="col-12 mt-5 text-center">
            <lottie-player
              src="https://assets8.lottiefiles.com/packages/lf20_mkzxw4th.json"
              background="transparent"
              speed="1"
              style={{
                width: "200px",
                height: "200px",
                margin: "auto",
              }}
              loop
              // controls
              autoplay
            ></lottie-player>

            <p style={{ fontSize: "32px", color: "#111" }}>
              We are calculating your metabolic score...
            </p>
          </div>
        </div>
      ) : (
        <div>
          {console.log("quizData...", quizData)}
          <ResultPage
            userQuizResult={quizData.userQuizResult}
            finalQuizScore={quizData.finalQuizScore}
            calendlyUrl={`https://calendly.com/twin-reversal-webinar/one-on-one-free-counselling-with-sr-health-counselor?month=${currentYear}-${currentMonth}&name=${quizData.userName}&email=${quizData.userEmail}`}
            bmiScore={quizData.bmiScore}
            riskState={quizData.riskState}
            potentialRisk={quizData.potentialRisk}
            ctEvent="metabolism_assessment_resultPageCta"
          />
        </div>
      )}
    </div>
  );
};

export default DiabetesQuizResultPage;
