import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const propTypes = {
  percentage: PropTypes.number,
  strokeWidth: PropTypes.number,
  size: PropTypes.number,
  circle1BkgColor: PropTypes.string,
  circle2BkgColor: PropTypes.string,
  stroke1Color: PropTypes.string,
  stroke2Color: PropTypes.string,
  textColor: PropTypes.string,
};

const defaultProps = {
    percentage: 50,
    strokeWidth: 10,
    size: 150,
    circle1BkgColor: "none",
    circle2BkgColor: "none",
    stroke1Color: "#00bcd4",
    stroke2Color: "#e0e0e0",
    textColor: "#111111",
};

const CircleProgressBar = ({
  percentage,
  strokeWidth,
  size,
  circle1BkgColor,
  circle2BkgColor,
  stroke1Color,
  stroke2Color,
  textColor,
}) => {
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const dashOffset = circumference - (percentage / 100) * circumference;

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    setOffset(dashOffset);
  }, [dashOffset]);

  return (
    <svg width={size} height={size}>
      <g transform={`rotate(-90 ${size / 2} ${size / 2})`}>
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={strokeWidth}
          style={{ fill: circle1BkgColor, stroke: stroke2Color }}
        />
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          style={{
            fill: circle2BkgColor,
            stroke: stroke1Color,
            strokeLinecap: "round",
          }}
        />
      </g>
      <text
        x="50%"
        y="50%"
        style={{
          fontSize: "45px",
          textAnchor: "middle",
          dominantBaseline: "middle",
          fill: textColor,
          fontWeight: "700",
        }}
      >
        {percentage}
      </text>
      <text
        x="50%"
        y="70%"
        style={{
          fontSize: "16px",
          textAnchor: "middle",
          dominantBaseline: "middle",
          fill: textColor,
        }}
      >
        out of 100
      </text>
    </svg>
  );
};

CircleProgressBar.propTypes = propTypes;
CircleProgressBar.defaultProps = defaultProps;

export default CircleProgressBar;
